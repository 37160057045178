<template>
<div>
    <!-- EDICION Y CREACION DE REQUERIMIENTOS EN VENTANA MODAL  -->
    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <lgl-requirement-edit :value="RequirementEdit.ReqID" @close="dialog = false" :RequirementEdit="RequirementEdit" :order="{CttID:null}"></lgl-requirement-edit>
        </v-dialog>
    </div>

    <div>
        <v-dialog v-if="dialogOrder" v-model="dialogOrder" persistent transition="dialog-bottom-transition">
            <order-edit @onClose="dialogOrder = false;"  @onCloseClear="dialogOrder = false;" :value="OrdID"></order-edit>
        </v-dialog>
    </div>

    <!-- LISTADO DE REQUERIMEINTOS POR TIPO   -->
    <v-row>
        <v-col style="padding-bottom: 0px">
            <s-crud noFull height="400" ref="grid" :view="selected.length > 0 ? (selected[0].ReqStatus != 5? true : false) : false" @rowSelected="rowSelected($event)" search-input="" add-no-function :filter="filterRequirement" @add="add()" :title="label" add @edit="edit()" :remove="selected.length > 0 ? (selected[0].ReqStatus == 5 ? true : false) : false" :config="config" @doubleClick="doubleClick($event)">
                <template v-slot:filter>
                    <v-container>
                        <v-container>
                            <v-row justify="center">

                                <v-col lg="2" md="2" cols="6" class="s-col-form">
                                    <s-select-definition label="Empresa" clearable v-model="filterRequirement.typeBusiness" :def="1142" />
                                </v-col>

                                <v-col lg="3" md="3" cols="6" class="s-col-form">
                                    <s-select-definition clearable label="Estados" noDefault v-model="filterRequirement.ReqStatus" :def="1180" />
                                </v-col>
                                <v-col lg="3" md="3" cols="12" class="s-col-form" v-if="$fun.isAdmin()">
                                    <c-select-area full clearable noDefault v-model="filterRequirement.AreID" label="Área" />
                                </v-col>
                                <v-col lg="2" md="2" cols="6" class="s-col-form">
                                    <s-date v-model="filterRequirement.BeginDate" clearable label="Fecha Inicio" />
                                </v-col>
                                <v-col lg="2" md="2" cols="6" class="s-col-form">
                                    <s-date v-model="filterRequirement.EndDate" clearable label="Fecha Fin" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-container>
                </template>

                <template v-slot:ReqStatusName="{ row }">
                    <v-chip x-small :color="row.ReqStatusColor">
                        {{ row.ReqStatusName }}<span>
                            <v-btn style="margin-left:10px" icon x-small elevation="0" dark @click="dialogApprovalRequirement= true"><i class="fas fa-eye"></i></v-btn>
                        </span>
                    </v-chip>

                </template>

                <template v-slot:TypePriorityName="{ row }">
                    <v-chip x-small :color="row.TypePriorityColor">
                        {{ row.TypePriorityName }}
                    </v-chip>
                </template>
                <template v-slot:ReqProcessInitiated="{ row }">
                    <v-chip x-small :color="row.ReqProcessInitiated == null && row.ReqProcessEnd == null ? 'error' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == null ? 'info' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == 1 ? '' : ''">
                        {{ row.ReqProcessInitiated == null && row.ReqProcessEnd == null ? 'Sin Iniciar' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == null ? 'Iniciado' : row.ReqProcessInitiated == 1 && row.ReqProcessEnd == 1 ? 'Finalizado' : '' }}
                    </v-chip>
                </template>
                <template v-slot:OrdDocumentNumber="{ row }">
                    <a style="text-decoration:underline" @click="openOrder(row)">{{ row.OrdDocumentNumber}}</a>
                </template>
                <template v-slot:CttDocumentNumber="{ row }">
                    <a style="text-decoration:">{{ row.CttDocumentNumber}}</a>
                </template>
            </s-crud>
        </v-col>
    </v-row>

    <!-- NIVELES DE APROBACION DE REQ-->
    <v-dialog v-model="dialogApprovalRequirement" v-if="dialogApprovalRequirement" width="60%" style="display: inline-table;">
        <ApprovalLevelsDocument :DocEntry="ReqID" :TypeObject="TypeCircuit" />
    </v-dialog>
</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Legal/LglRequirementService";
import _sTransaction from '@/services/Security/ApprovalTransactionService'

//Components
import LglRequirementEdit from "@/views/Legal/Requirement/LglRequirementEdit";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
import OrderEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";

export default {
    name: "RequirementService",
    components: {
        LglRequirementEdit,
        ApprovalLevelsDocument,
        OrderEdit
    },
    props: {
        label: {
            default: "Requerimiento",
            type: String
        },
    },
    data() {
        return {
            OrdID: null,
            dialogOrder: false,
            dialogApproval: false,
            dialogApprovalRequirement: false,
            dialogHistory: false,
            itemHistory: [],
            itemReq: {},
            DocEntry: null,
            ReqID: null,
            OrdID: null,
            TypeCircuit: null,
            RequirementEdit: {},
            TypeCost: 0,
            centercost: 0,
            bslID: 0,
            dialog: false,
            TypeRequirement: 1,
            filterRequirement: {
                BeginDate: null,
                EndDate: null,
                TypeRequirement: 1,
                AreID: 0,
                ReqStatus: 4,
                typeBusiness: null
            },
            selected: [],
            itemsDetail: [],
            itemsDetailSelect: [],
            config: {
                model: {
                    ReqDocumentNumber: "ID",
                    OrdDocumentNumber: "",
                    CttDocumentNumber:"",
                    ReqDate: "datetime",
                    SecUpdate: "datetime",
                    ReqStatusName: "",
                    TypePriorityName: "string",
                    TypeCotizacionName: "string",
                    MailOK: "string",
                    ReqProcessInitiated: "",
                    ReqDateBegin:"date",
                    ReqDateEnd: "date"


                },
                service: _sRequirement,
                headers: [/* {
                        text: "ID",
                        value: "ReqID",
                        sortable: false,
                        width: 40
                    }, */
                    {
                        text: "Proceso",
                        value: "ReqProcessInitiated",
                        sortable: false,
                        width: 50,
                    },
                    {
                        text: "N° Requerimiento",
                        value: "ReqDocumentNumber",
                        sortable: false,
                        width: 100,

                    },
                    {
                        text: "N° Contrato",
                        value: "ReqNroContract",
                        sortable: false,
                        width: 155,
                    },
                    {
                        text: "Fec. Req",
                        value: "ReqDate",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Prestador/Proveedor/Ejecutor",
                        value: "CardName",
                        sortable: false,

                    },
                    {
                        text: "RUC",
                        value: "SupCode",
                        sortable: false,

                    },
                    {
                        text: "Empresa",
                        value: "nameBusiness",
                        sortable: false,
                        width: 60
                    },
                    {
                        text: "Tipo R.",
                        value: "ObjectTypeName",
                        sortable: false,

                    },
                    {
                        text: "Sub Tipo",
                        value: "TypeProcessName",
                        sortable: false,
                        width: 150,

                    },
                    {
                        text: "Estado",
                        value: "ReqStatusName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Fecha Inicio",
                        value: "ReqDateBegin",
                        sortable: false,
                        width: 110
                    },
                    {
                        text: "Fecha Fin",
                        value: "ReqDateEnd",
                        sortable: false,
                        width: 140
                    },
                    {
                        text: "Solicitado Por.",
                        value: "UsrName",
                        sortable: false,
                        width: 150,

                    },
                    {
                        text: "Prioridad",
                        value: "TypePriorityName",
                        sortable: false,
                        width: 50,
                        align: "center",
                    },
                    {
                        text: "Nro. Doc.Orden",
                        value: "OrdDocumentNumber",
                        width: 20,
                        sortable: false
                    },
                    {
                        text: "Nro. Cot.",
                        value: "CttDocumentNumber",
                        width: 20,
                        sortable: false
                    },
                    {
                        text: "Tiemp. Proceso",
                        value: "ReqDay",
                        sortable: false,
                        width: 40,
                        align: "center",
                    },

                    {
                        text: "Área",
                        value: "AreName",
                        sortable: false
                    },
                    
                    /* {
                        text: "Tipo G.",
                        value: "TypeGenerationName",
                        sortable: false,

                    }, */
                    
                    /* {
                        text: "Ok",
                        value: "MailOK",
                        sortable: false
                    }, */

                ],
            },
            miHtmlEmail: [],
            dialogHtmlEmail: false,
        }
    },
    created() {
        // SI ES USUARIO ADMNISTRADOR PUEDE HACER FILTROS POR AREA SINO SOLO PUEDE VER LOS DE SU AREA
        if (!this.$fun.isAdmin()) this.filterRequirement.AreID = this.$fun.getUserInfo().CtrContract.AreID;
    },

    watch: {
        dialog() {
            this.$refs.grid.refresh();
        },

    },

    methods: {

        //seleccionar registro por defecto
        rowSelected(item) {
            this.selected = item;
            console.log(this.selected)
            if (item != null) {
                if (item.length > 0) {
                    if (item[0].ReqID != null)
                        this.ReqID = item[0].ReqID
                    this.TypeCircuit = item[0].TypeCircuit
                }
            }
        },
        //Nuevo Registro
        add() {

            this.RequirementEdit.ReqID = null;
            this.dialog = true;
        },

        //Editar Requerimiento
        edit() {
            this.RequirementEdit = this.selected.length > 0 ? this.selected[0] : null;
            //this.RequirementEdit.items = this.itemsDetail;
            this.dialog = true;
        },

        doubleClick(item) {
            this.RequirementEdit = item !== null ? item : null;
            this.dialog = true;
        },

        openOrder(row){
            this.OrdID = row.OrdID
            this.dialogOrder= true
        }

    },

};
</script>
